<template>
  <div class="comment_container">
    <!-- 评论点赞图标 -->
    <div v-if="isShow" class="oprationIcon">
      <i class="iconfont icon-pinglun" @click="openComment(false)"></i>
      <!-- <i class="iconfont icon-fenxiang1" @click="toShare"></i> -->
    </div>
    <!-- 点赞评论-->
    <div class="praise_comment">
      <van-list v-model="loading" :finished="finished" finished-text="暂无更多评论" @load="onLoad">
        <!-- <comment-list
          v-if="commentList && commentList.length > 0"
          :list="commentList"
        ></comment-list>-->
        <div v-if="commentList && commentList.length > 0" class="commentContent">
          <ul class="columnContent">
            <li v-for="(item, index) in commentList" :key="index">
              <img
                :src="
                  item.fromHeadPhoto
                    ? item.fromHeadPhoto
                    : require('../../assets/images/user.png')
                "
                alt
              />
              <div class="rootComment">
                <div class="userInfo">
                  <span>{{ item.fromName ? item.fromName : "未知用户" }}</span>
                  <span v-if="isShow" @click="onReply(item)">回复</span>
                </div>
                <p>{{ item.releaseContent }}</p>
                <div
                  v-if="item.childsize"
                  class="replyBox"
                  @click="openReplyPop(item)"
                >共{{ item.childsize }}条回复 ></div>
              </div>

              <!-- 弹窗中的评论与回复 -->
              <van-popup
                v-model="replayPop"
                closeable
                round
                position="bottom"
                :style="{ height: '80%', background: '#F2F2F2' }"
              >
                <div class="reply_title">{{ popContent.childsize }}条回复</div>
                <div class="reply_head reply_back">
                  <img
                    :src="
                      popContent.fromHeadPhoto
                        ? popContent.fromHeadPhoto
                        : require('../../assets/images/user.png')
                    "
                    alt
                  />
                  <div class="head_comment">
                    <div class="userInfo">
                      <span>
                        {{
                        popContent.fromName ? popContent.fromName : "未知用户"
                        }}
                      </span>
                    </div>
                    <p>{{ popContent.releaseContent }}</p>
                  </div>
                </div>
                <div style="background: #ddd; height: 10px"></div>
                <div v-for="replyItem in replyList" :key="replyItem.id">
                  <div class="reply_head">
                    <img
                      :src="
                        replyItem.fromHeadPhoto
                          ? replyItem.fromHeadPhoto
                          : require('../../assets/images/user.png')
                      "
                      alt
                    />
                    <div class="head_comment head_border">
                      <div class="userInfo">
                        <span>
                          {{
                          replyItem.fromName ? replyItem.fromName : "未知用户"
                          }}
                        </span>

                        <span v-if="isShow" @click="onReply(replyItem)">回复</span>
                      </div>
                      <!-- 二级回复 -->
                      <div v-if="replyItem.parentId !== popContent.id">
                        <span>回复</span>
                        <span style="color: #107da8">@{{ replyItem.toName }}:</span>
                        <span>{{ replyItem.releaseContent }}</span>
                      </div>
                      <!-- 一级回复 -->
                      <p v-if="replyItem.parentId === popContent.id">{{ replyItem.releaseContent }}</p>
                    </div>
                  </div>
                </div>
                <div class="btn_wra" v-show="moreBtn">
                  <van-button
                    class="rep_mor"
                    loading-type="spinner"
                    :loading="reply_load"
                    @click="showMoreReply"
                  >展示更多评论</van-button>
                </div>
                <div v-show="emptyShow" class="emp_txt">暂无更多</div>
              </van-popup>
            </li>
          </ul>
          <div v-show="data.length == 0" class="noComment">暂无评论</div>
        </div>
      </van-list>
    </div>
    <!-- 评论 -->
    <van-action-sheet v-model="showComment" title="回复" :round="false">
      <div class="wrapper">
        <div @click.stop class="commentBlock">
          <textarea
            v-model="ugcObj.releaseContent"
            rows="5"
            placeholder="评论（内容最多为100字）"
            id="pinglunContentOne"
            maxlength="100"
          ></textarea>
          <div>
            <button @click="fixScroll">取消</button>
            <button @click="onPublish">发表</button>
          </div>
        </div>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
// import CommentList from "./CommentList";
import { Toast } from "vant";
import InteractionApi from "@/api/interaction";

export default {
  props: ["detailData", "isShare"],
  data() {
    return {
      data: null, // 当前动态
      isShow: true,
      showComment: false, // 评论框显示
      commentContent: "", // 评论内容
      beenReplyComment: "", // 被回复的评论
      ugcObj: {
        parentId: 0,
        releaseContent: "", // 回复的内容
        ugcId: "", // ugc的Id
      },
      commentId: "", // 评论的Id
      replyId: "",
      commentList: [],
      loading: false,
      reply_load: false,
      reply_finish: false,
      finished: false,
      page: 0,
      reply_page: 1,
      replayPop: false,
      popContent: {}, // 弹窗中的评论内容
      replyList: [], // 评论下的回复列表
      moreBtn: false, // 控制展示更多回复的按钮
      emptyShow: false,
    };
  },
  components: {
    // CommentList,
  },
  mounted() {
    this.data = this.detailData;
    if (this.isShare) {
      this.isShow = false;
    }
    this.ugcObj.ugcId = this.data.ugcId;
    if (window.localStorage.userinfo && JSON.parse(window.localStorage.userinfo).id) {
      this.memberId = JSON.parse(window.localStorage.userinfo).id;
    }
  },
  watch: {
    showComment: function (dNew) {
      if (!dNew) {
        // 保证在关闭评论框后保存的评论父级id为空
        this.beenReplyComment = null;
      }
    },
  },
  methods: {
    // 打开评论弹框
    openComment(replyData) {
      if (this.isToken()) {
        this.showComment = true;
        this.beenReplyComment = replyData;
      }
    },
    // 打开回复框
    onReply(value) {
      if (this.isToken()) {
        this.showComment = true;
        this.beenReplyComment = value;
      }
    },
    // 打开回复列表面板
    openReplyPop(value) {
      // console.log(value);
      this.reply_page = 1;
      this.popContent = value;
      this.commentId = value.id;
      this.replyId = value.id;
      this.queryReply(this.ugcObj.ugcId);
      this.replayPop = true;
    },
    // 关闭评论框，并使软键盘回落到底部
    fixScroll() {
      window.scroll(0, 0);
      this.showComment = false;
    },
    // 发表评论
    onPublish() {
      if (!this.ugcObj.releaseContent) {
        return Toast("评论不能为空");
      }
      if (!this.beenReplyComment) {
        //父级
        this.ugcObj.parentId = 0;
      } else {
        this.ugcObj.parentId = this.beenReplyComment.id;
      }
      // console.log(this.ugcObj);
      InteractionApi.addComment(this.ugcObj, {
        appName: this.appName,
        memberId: this.memberId,
      })
        .then((resp) => {
          if (resp.success) {
            this.refshComment();
            Toast(resp.message);
            this.fixScroll();
            this.ugcObj.releaseContent = "";
          }
        })
        .catch((err) => {
          Toast.fail("发表失败");
          console.log(err);
        });
    },
    // 查询爆料下的评论
    queryComment(id) {
      const params = {
        appName: this.appName,
        page: this.page,
        rows: 10,
        ugcId: id,
        id: this.commentId,
      };
      // console.log(params);
      InteractionApi.getComment(params)
        .then((res) => {
          // console.log(res.data);
          this.loading = false;
          if (res.data.items && res.data.items.length > 0) {
            if (this.page === 1) {
              this.commentList = [];
            }
            this.commentList = this.commentList.concat(res.data.items);
            this.finished = false;
          } else {
            this.finished = true;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 查询回复
    queryReply(id) {
      const params = {
        appName: this.appName,
        page: this.reply_page,
        rows: 5,
        ugcId: id,
        id: this.replyId,
      };
      console.log(params);
      InteractionApi.getComment(params)
        .then((res) => {
          console.log(res.data);
          this.reply_load = false;
          if (res.data.items && res.data.items.length > 0) {
            if (this.reply_page === 1) {
              this.replyList = [];
            }
            this.replyList = this.replyList.concat(res.data.items);
          }
          if (res.data.items.length < 5) {
            // 展示更多按扭
            this.moreBtn = false;
            this.emptyShow = true;
          } else {
            this.moreBtn = true;
            this.emptyShow = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onLoad() {
      this.page += 1;
      this.queryComment(this.ugcObj.ugcId);
    },
    // 查询更多回复
    showMoreReply() {
      this.reply_page += 1;
      this.reply_load = true;
      this.queryReply(this.ugcObj.ugcId);
    },
    // 刷新评论和回复
    refshComment() {
      if (this.replayPop) {
        this.reply_page = 1;
        this.queryReply(this.ugcObj.ugcId);
      }
      this.page = 1;
      this.commentId = "";
      this.queryComment(this.ugcObj.ugcId);
    },
    // 分享
    toShare() {
      this.$emit('toShare');
    }
  },
};
</script>

<style lang="less" scoped>
.comment_container {
  /* 操作图标 */
  .oprationIcon {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 7px;
    margin-bottom: 13.5px;
    .iconfont {
      margin-left: 30px;
      font-size: 25px;
    }
    .myDianZan {
      color: #006abf;
    }
  }
  /* 评论点赞 */
  .praise_comment {
    width: 100%;
    // border-top: 1px solid #e6e6e6;
    .dianzan {
      font-size: 13px;
      margin: 10px 0px;
      color: #006abf;
    }
    .pinglun {
      width: 100%;
      margin: 10px auto;
      .pinglunAll {
        width: 100%;
        .huifuContent {
          font-size: 13px;
          /* letter-spacing: 1px; */
          width: 100%;
          height: 22px;
          line-height: 22px;
          letter-spacing: 0.5px;
        }
        .huifuContent:active {
          background: rgba(0, 0, 0, 0.1);
        }
        .yanseName {
          color: #006abf;
        }
      }
    }
  }
  .commentContent {
    width: 100%;
    //   margin-bottom: 55px;
    .columnTitle {
      text-align: center;
      padding: 5px 0;
    }
    .columnContent {
      height: calc(100% - 80px);
      li {
        padding: 10px 15px;
        overflow: hidden;
        display: flex;
        img {
          width: 22px;
          height: 22px;
          border-radius: 50%;
          background-color: #107da8;
          padding: 3px;
          box-sizing: border-box;
        }
        .rootComment {
          width: calc(100% - 22px);
          padding: 0 10px;
          box-sizing: border-box;
          border-bottom: 1px solid #efefef;
          padding-bottom: 10px;
          .userInfo {
            display: flex;
            justify-content: space-between;
            line-height: 22px;
            span {
              font-size: 12px;
              &:last-child {
                color: #bbb;
              }
            }
          }
          .replyBox {
            margin-top: 10px;
            padding: 3px 0 3px 15px;
            background: #efefef;
            color: #107da8;
          }
          p {
            font-size: 14px;
          }
          .childComment {
            overflow: hidden;
            display: flex;
            margin-top: 10px;
            padding-top: 10px;
            border-top: 1px solid #efefef;
            > div {
              width: calc(100% - 22px);
              padding-left: 10px;
              box-sizing: border-box;
            }
          }
        }
      }
    }
    .noComment {
      text-align: center;
      height: 100px;
      line-height: 100px;
    }
  }
  .reply_title {
    text-align: center;
    padding: 12px 0 10px;
    // margin-top: 10px;
    border-bottom: 1px solid#efefef;
    background: #fff;
  }
  .reply_head {
    display: flex;
    padding: 5px 0 0 10px;
    .head_comment {
      width: calc(100% - 22px);
      padding: 0 10px 10px;
      box-sizing: border-box;
      .userInfo {
        display: flex;
        justify-content: space-between;
        line-height: 22px;
        span {
          font-size: 13px;
          &:last-child {
            color: #bbb;
          }
        }
      }
    }
    .head_border {
      border-bottom: 1px solid #ddd;
    }
  }
  .btn_wra {
    width: 50vw;
    margin: 0 auto;
    padding: 2vw 0;
    .rep_mor {
      width: 100%;
      height: 8vw;
      background: #1989fa;
      text-align: center;
      line-height: 8vw;
      color: #fff;
    }
  }
  .reply_back {
    background: #fff;
  }
  /* 评论样式 */
  .wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    .commentBlock {
      width: 100%;
      padding: 10px 20px;
      background-color: #fff;
      textarea {
        width: 100%;
        border: 1px solid #ddd;
        border-radius: 3px;
        padding: 10px;
        box-sizing: border-box;
        resize: none;
      }
      div {
        display: flex;
        justify-content: space-between;
      }
      button {
        font-size: 14px;
        outline: none;
        border: 0;
        background-color: #fff;
        padding: 3px 8px;
      }
    }
  }
  .emp_txt {
    text-align: center;
    color: rgba(168, 166, 166, 0.863);
    font-size: 14px;
    padding: 4px 0;
  }
}
</style>