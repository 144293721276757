<template>
  <div class="container titlesTop">
    <headTitle title="爆料详情" />
    <div>
      <div class="card_box" v-if="list">
        <!-- 头部信息 -->
        <div class="myInformation">
          <!-- 头像 -->
          <div class="headPortrait">
            <img v-if="list.headPhoto" :src="list.headPhoto" alt="" />
            <img v-else src="../../assets/Headportrait.png" alt="" />
          </div>
          <div class="nameAndTime">
            <p>{{ list.memberName }}</p>
            <p class="smallTime">{{ list.createTime }}</p>
          </div>
        </div>
        <!-- 文字内容 -->
        <div class="textContent" v-if="list.textContent">
          {{ list.textContent }}
        </div>
        <!-- 视频 -->
        <div
          class="vedioStyleBox"
          v-if="
            JSON.parse(list.videoUrl) && JSON.parse(list.videoUrl).length > 0
          "
        >
          <div
            class=""
            v-for="(vdm, vdx) in JSON.parse(list.videoUrl)"
            :key="vdx"
          >
            <div class="coverImg" v-if="thumbBlock">
              <img class="thumbImg" src="../../assets/bvd.png" alt />
              <img
                @click="vedioOpenPlay()"
                class="vedioButtonSty"
                src="../../assets/vedioSing.png"
                alt
              />
            </div>
            <video
              class="vedioContent"
              playsinline
              webkit-playsinline
              x5-video-player-type="h5"
              x5-video-player-fullscreen="true"
              :src="vdm.url"
              controls
              :id="'datailVedio'"
            ></video>
          </div>
        </div>
        <!-- 音频 -->
        <div
          class="audioContentBox"
          v-if="
            JSON.parse(list.audioUrl) && JSON.parse(list.audioUrl).length > 0
          "
        >
          <div
            class="v_for"
            v-for="(adm, adx) in JSON.parse(list.audioUrl)"
            :key="adx"
          >
            <div>
              <img class="thumbImg" src="../../assets/vedioMoRen.png" alt />
              <img
                v-show="thumbBlock"
                @click="audioOpenPlay()"
                class="vedioButtonSty"
                src="../../assets/vedioSing.png"
                alt
              />
              <img
                v-show="!thumbBlock"
                @click="audioOpenPlay()"
                class="vedioButtonSty"
                src="../../assets/vplay.png"
                alt
              />
              <audio
                class="vedioContent"
                playsinline
                webkit-playsinline
                x5-video-player-type="h5"
                x5-video-player-fullscreen="true"
                :src="adm.url"
                :id="'datailAudio'"
              ></audio>
            </div>
          </div>
        </div>
        <!-- 图片(必须是数组) -->
        <div
          v-if="JSON.parse(list.picUrl) && JSON.parse(list.picUrl).length > 0"
        >
          <show-picture :pictureList="JSON.parse(list.picUrl)"></show-picture>
        </div>
        <!-- 位置 -->
        <div v-if="list.position" class="positionCon">
          <i class="iconfont icon-weizhi"></i>
          {{ list.position }}
        </div>
        <comment
          v-if="list"
          @toShare="shareData(list)"
          :detailData="list"
          :isShare="share"
        ></comment>
      </div>
    </div>
  </div>
</template>

<script>
import ShowPicture from "./ShowPicture";
import Comment from "./Comment";
import InteractionApi from "@/api/interaction";
import { Toast } from "vant";

export default {
  data() {
    return {
      list: null,
      commentId: "",
      id: "",
      share: null,
      thumbBlock: true,
    };
  },
  components: {
    ShowPicture,
    Comment,
  },
  created() {
    // var that = this;
    // this.setupWebViewJavascriptBridge(function (bridge) {
    //   bridge.registerHandler("stopPlay", function (data, responseCallback) {
    //     that.closeAudio();
    //     that.closeVedio();
    //     var responseData = { "Javascript Says": "Right back atcha!" };
    //     responseCallback(responseData);
    //   });
    // });
  },
  mounted() {
    this.id = this.$route.query.id;
    this.share = this.$route.query.share;
    // console.log(this.$route.query);
    this.getDynamicById(this.id);
  },
  methods: {
    // 查询爆料详情
    getDynamicById(id) {
      InteractionApi.getDetailById({ ugcId: id })
        .then((resp) => {
          // console.log(resp);
          if (resp.success) {
            this.list = resp.data;
            // console.log(this.list);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 播放视频
    vedioOpenPlay() {
      const video = document.getElementById("datailVedio");
      if (video) {
        if (video.paused) {
          this.thumbBlock = false;
          video.play();
        }
      }
    },

    // 播放音药
    audioOpenPlay() {
      const audio = document.getElementById("datailAudio");
      if (audio) {
        if (audio.paused) {
          this.thumbBlock = false;
          audio.play();
        } else {
          this.thumbBlock = true;
          audio.pause();
        }
        audio.addEventListener(
          "ended",
          () => {
            this.thumbBlock = true;
            this.$forceUpdate();
            audio.pause();
          },
          false
        );
        audio.addEventListener(
          "pause",
          () => {
            this.thumbBlock = true;
            this.$forceUpdate();
            audio.pause();
          },
          false
        );
      }
    },
    // 关闭播放的音药
    closeAudio() {
      const lastAudio = document.getElementById("datailAudio");
      if (lastAudio) {
        this.thumbBlock = true;
        this.$forceUpdate();
        lastAudio.pause();
      }
    },
    // 关闭播放的视频
    closeVedio() {
      const lastVedio = document.getElementById("datailVedio");
      if (lastVedio) {
        this.thumbBlock = true;
        this.$forceUpdate();
        lastVedio.pause();
      }
    },
    // 分享
    shareData(data) {
      var describe = data.textContent.replace(/&nbsp;/g, "").slice(0, 50); //内容标题
      var photo;
      if (data.thumb) {
        //封面图
        photo = data.thumb;
      } else if (JSON.parse(data.picUrl).length > 0) {
        photo = JSON.parse(data.picUrl)[0].url;
      } else {
        photo = "";
      }
      var link =
        window.location.href.split("#/")[0] +
        "#/interactionDetail?id=" +
        data.ugcId +
        "&share=true"; //链接
      this.shareContent({
        url: link,
        title: describe,
        thumb: photo,
        describe: describe,
      });
    },
  },
};
</script>
<style>
.van-dialog {
  border-radius: 0;
}
</style>
<style lang="less" scoped>
.container {
  padding-left: 3vw;
  padding-right: 3vw;
  .card_box {
    .myInformation {
      width: 100%;
      height: 30px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 14px 0 8px 0;
      .headPortrait {
        width: 30px;
        height: 30px;
        img {
          width: 30px;
          height: 30px;
          object-fit: cover;
          border-radius: 100%;
        }
      }
      .nameAndTime {
        margin-left: 5px;
        font-size: 13px;
        width: calc(100% - 70px);
        .smallTime {
          font-size: 10px;
          color: #afafaf;
          margin-top: 2px;
        }
      }
    }
    .textContent {
      width: 100%;
      font-size: 16px;
      word-break: break-all;
    }
    .positionCon {
      font-size: 0.3rem;
      color: #006abf;
      margin-bottom: 13.5px;
      margin-top: 5px;
      .iconfont {
        font-size: 0.3rem;
      }
    }
    .vedioStyleBox {
      width: 100%;
      // height: 190px;
      position: relative;
      /* margin-bottom: 12px; */
      .coverImg {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
        z-index: 3;
        .thumbImg {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .vedioButtonSty {
          width: 36px;
          height: 36px;
          position: absolute;
          left: 50%;
          top: 50%;
          margin-left: -18px;
          margin-top: -18px;
          z-index: 1000;
        }
      }
      .vedioContent {
        width: 100%;
        height: 190px;
        object-fit: contain;
      }
    }
    .audioContentBox {
      width: 100%;
      // margin-top: 7px;
      // height: 190px;
      position: relative;
      .v_for {
        position: relative;
        z-index: 1;
        .thumbImg {
          width: 100%;
          height: 190px;
        }
        .vedioButtonSty {
          width: 36px;
          height: 36px;
          position: absolute;
          left: 50%;
          top: 50%;
          margin-left: -18px;
          margin-top: -18px;
          z-index: 10;
        }
      }
      .vedioContent {
        width: 100%;
        height: 190px;
        object-fit: contain;
      }
    }
  }
}
</style>